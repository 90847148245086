import { jsx as _jsx } from "react/jsx-runtime";
import { useMapsLibrary } from "@vis.gl/react-google-maps";
import { CatInputField } from "blocks-nextjs/blocks-react/components/InputField/InputField";
import { useEffect, useRef, useState } from "react";
import { Controller } from "react-hook-form";
const PlaceAutocomplete = ({ control, errors, onPlaceSelect }) => {
    const [placeAutocomplete, setPlaceAutocomplete] = useState(null);
    const inputRef = useRef(null);
    const places = useMapsLibrary("places");
    useEffect(() => {
        if (!places || !inputRef.current)
            return;
        const options = {
            fields: ["geometry", "name", "formatted_address"]
        };
        setPlaceAutocomplete(new places.Autocomplete(inputRef.current, options));
    }, [places]);
    useEffect(() => {
        if (!placeAutocomplete)
            return;
        placeAutocomplete.addListener("place_changed", () => {
            onPlaceSelect(placeAutocomplete.getPlace());
        });
    }, [onPlaceSelect, placeAutocomplete]);
    return (_jsx("div", { className: "autocomplete-container", children: _jsx(Controller, { control: control, name: "projectAddress", render: ({ field: { onBlur, onChange, value } }) => (_jsx(CatInputField, { ariaDescribedBy: "text-field-aria-1", fieldId: "text-field-1", label: "Address", onBlChange: onChange, onBlur: onBlur, placeholder: "Enter Address", ref: inputRef, required: true, size: "lg", value: value, ...(errors.projectAddress && {
                    errorNote: errors.projectAddress.message,
                    status: "error"
                }) })) }) }));
};
export default PlaceAutocomplete;
