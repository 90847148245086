import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { zodResolver } from "@hookform/resolvers/zod";
import { APIProvider } from "@vis.gl/react-google-maps";
import { CatButton } from "blocks-nextjs/blocks-react/components/Button/Button";
import { CatCard } from "blocks-nextjs/blocks-react/components/Card/Card";
import { CatHeading } from "blocks-nextjs/blocks-react/components/Heading/Heading";
import { CatInputField } from "blocks-nextjs/blocks-react/components/InputField/InputField";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { z } from "zod";
import { getGoogleMapsApiKey } from "../../lib/getEnvVariables";
import { CaasRoute } from "../../routes";
import { useCreateProjectMutate } from "../../services/query/create-project-api.query";
import { useUpdateProjectDetailsMutate } from "../../services/query/update-project-details.query";
import useCreateNewProjectStore from "../../store/useCreateNewProjectStore";
import useGlobalStore from "../../store/useGlobalStore";
import { Variant } from "../GlobalNotification/GlobalNotification";
import GoogleMapLocator from "../GoogleMaps/GoogleMapLocator";
import PlaceAutocomplete from "../GoogleMaps/PlaceAutoComplete";
import { ProjectStatus } from "../Projects/ProjectSummaryEnum";
import NewProjectModal from "./NewProjectModal";
const API_KEY = getGoogleMapsApiKey();
const NewProjectForm = ({ activeStep, setActiveStepHandler }) => {
    const [selectedPlace, setSelectedPlace] = useState(null);
    const [isModalActive, setIsModalActive] = useState(false);
    const CHARACTERS = 3;
    const { isUpdateProjectDetails, projectAddress, projectId, projectName, projectOwner, setIsUpdateProjectDetails, setProjectAddress, setProjectId, setProjectName, setProjectOwner, setReset, setUpdateProjectAddress, setUpdateProjectName, setUpdateProjectOwner, updateProjectAddress, updateProjectName, updateProjectOwner } = useCreateNewProjectStore((state) => ({
        isUpdateProjectDetails: state.isUpdateProjectDetails,
        projectAddress: state.projectAddress,
        projectId: state.projectId,
        projectName: state.projectName,
        projectOwner: state.projectOwner,
        setIsUpdateProjectDetails: state.setIsUpdateProjectDetails,
        setProjectAddress: state.setProjectAddress,
        setProjectId: state.setProjectId,
        setProjectName: state.setProjectName,
        setProjectOwner: state.setProjectOwner,
        setReset: state.setReset,
        setUpdateProjectAddress: state.setUpdateProjectAddress,
        setUpdateProjectName: state.setUpdateProjectName,
        setUpdateProjectOwner: state.setUpdateProjectOwner,
        updateProjectAddress: state.updateProjectAddress,
        updateProjectName: state.updateProjectName,
        updateProjectOwner: state.updateProjectOwner
    }));
    const location = useLocation();
    const pendingProjectDetails = location.state;
    const pendingProjectInfo = pendingProjectDetails?.project;
    useEffect(() => {
        if (pendingProjectInfo?.projectStatus.toLowerCase() === ProjectStatus.pending && !isUpdateProjectDetails) {
            if (pendingProjectInfo.owner)
                setProjectOwner(pendingProjectInfo.owner);
            if (pendingProjectInfo.streetAddress)
                setProjectAddress(pendingProjectInfo.streetAddress);
            if (pendingProjectInfo.projectName)
                setProjectName(pendingProjectInfo.projectName);
            if (pendingProjectInfo.projectId)
                setProjectId(pendingProjectInfo.projectId);
            setIsUpdateProjectDetails(true);
        }
    }, [pendingProjectInfo]);
    const NewProjectSchema = z.object({
        owner: z.string().trim().min(CHARACTERS, { message: "Enter owner" }),
        projectAddress: z.string().trim().min(CHARACTERS, { message: "Enter Address" }),
        projectName: z.string().trim().min(CHARACTERS, { message: "Enter project name" })
    });
    const UpdateProjectDetailsSchema = z
        .object({
        owner: z.string(),
        projectAddress: z.string(),
        projectName: z.string()
    })
        .optional()
        .refine((data) => (data.projectName && data.projectName !== projectName) ||
        (data.projectAddress && data.projectAddress !== projectAddress) ||
        (data.owner && data.owner !== projectOwner), "At least one field should be provided");
    const ValidateSchema = isUpdateProjectDetails ? UpdateProjectDetailsSchema : NewProjectSchema;
    const defaultValuesInfo = isUpdateProjectDetails
        ? {
            owner: projectOwner,
            projectAddress,
            projectName
        }
        : {
            owner: "",
            projectAddress: "",
            projectName: ""
        };
    const { control, formState: { dirtyFields, errors, isDirty, isValid }, handleSubmit, reset, setError, setValue, watch } = useForm({
        defaultValues: defaultValuesInfo,
        mode: "onBlur",
        resolver: zodResolver(ValidateSchema)
    });
    const navigate = useNavigate();
    useEffect(() => {
        if (pendingProjectInfo?.projectStatus.toLowerCase() === ProjectStatus.pending) {
            reset({
                owner: projectOwner,
                projectAddress,
                projectName
            });
        }
    }, [isUpdateProjectDetails]);
    const { setGlobalNotification, userPartyNumber } = useGlobalStore((state) => ({
        setGlobalNotification: state.setGlobalNotification,
        userPartyNumber: state.userPartyNumber
    }));
    const { mutate: createMutate } = useCreateProjectMutate(userPartyNumber);
    const { mutate: updateMutate } = useUpdateProjectDetailsMutate(userPartyNumber, projectId, "");
    useEffect(() => {
        if (selectedPlace?.formatted_address) {
            setValue("projectAddress", selectedPlace?.formatted_address);
        }
    }, [selectedPlace]);
    const getMessage = () => {
        return isUpdateProjectDetails
            ? "Update has failed.Please try later"
            : `Creating new project ${projectName} has failed.Please try later`;
    };
    const mutateParam = {
        onError: (error) => {
            const responseData = error.response?.data;
            const badRequest = 400;
            if (error.response.status === badRequest && responseData.description === "Project already exists") {
                setError("projectName", {
                    message: "Project name already exists.Please select another name",
                    type: "manual"
                });
            }
            else {
                setGlobalNotification({
                    message: getMessage(),
                    showNotification: true,
                    type: Variant.error
                });
                navigate(CaasRoute.Home);
            }
        },
        onSuccess: (data) => {
            if (!isUpdateProjectDetails) {
                setProjectId(data?.projectId);
            }
            if (isUpdateProjectDetails) {
                if (updateProjectName && updateProjectName !== projectName)
                    setProjectName(updateProjectName);
                if (updateProjectOwner && updateProjectName !== projectOwner)
                    setProjectOwner(updateProjectOwner);
                if (updateProjectAddress && updateProjectName !== projectAddress)
                    setProjectAddress(updateProjectAddress);
            }
            setIsUpdateProjectDetails(true);
            setActiveStepHandler(activeStep + 1);
        }
    };
    const onSubmit = (formData) => {
        const updateProjectDetails = {
            ...(updateProjectOwner && { owner: updateProjectOwner }),
            ...(updateProjectAddress && { projectAddress: updateProjectAddress }),
            ...(updateProjectName && { projectName: updateProjectName })
        };
        if (isUpdateProjectDetails && isValid && isDirty) {
            updateMutate(updateProjectDetails, mutateParam);
        }
        if (!isUpdateProjectDetails) {
            createMutate(formData, mutateParam);
        }
    };
    const onModalClose = (modalState) => {
        setIsModalActive(modalState);
    };
    //setting create project valid fields indepently into store to make api call from header component
    //setting update project details valid fields indepently into store to make api call from header component
    useEffect(() => {
        if (isValid && isDirty && !isUpdateProjectDetails) {
            if (projectName !== watch("projectName")) {
                setProjectName(watch("projectName"));
            }
            if (projectOwner !== watch("owner")) {
                setProjectOwner(watch("owner"));
            }
            if (projectAddress !== watch("projectAddress")) {
                setProjectAddress(watch("projectAddress"));
            }
        }
        if (isValid && isUpdateProjectDetails) {
            //To store updateProjectName into store
            if (updateProjectName !== watch("projectName") && dirtyFields?.projectName) {
                setUpdateProjectName(watch("projectName"));
            }
            else {
                //removes default value
                if (updateProjectName !== "" && !dirtyFields?.projectName) {
                    setUpdateProjectName("");
                }
            }
            if (updateProjectOwner !== watch("owner") && dirtyFields?.owner) {
                setUpdateProjectOwner(watch("owner"));
            }
            else {
                if (updateProjectOwner !== "" && !dirtyFields?.owner) {
                    setUpdateProjectOwner("");
                }
            }
            if (updateProjectAddress !== watch("projectAddress") && dirtyFields?.projectAddress) {
                setUpdateProjectAddress(watch("projectAddress"));
            }
            else {
                if (updateProjectAddress !== "" && !dirtyFields?.projectAddress) {
                    setUpdateProjectAddress("");
                }
            }
        }
    });
    useEffect(() => {
        return () => {
            const { pathname } = window.location;
            if (pathname !== CaasRoute.CreateNewProject) {
                setReset();
            }
        };
    }, []);
    return (_jsxs("form", { onSubmit: handleSubmit(onSubmit), children: [_jsxs("div", { className: "px-7 mt-2.5", children: [_jsxs("div", { className: "flex flex-col gap-1", children: [_jsx(CatHeading, { variant: "title-sm", children: "New Project Information" }), _jsx(CatHeading, { variant: "footnote", children: "Please complete the project work site information to create a new record." })] }), _jsxs("div", { className: "flex h-96 gap-2 my-3.5", children: [_jsx("div", { className: "w-1/4", children: _jsx(CatCard, { isAutoHeight: true, removePadding: true, children: _jsxs("div", { className: "flex flex-col gap-3 bg-[#F2F2F2] p-4 rounded-lg", children: [_jsx(CatHeading, { variant: "title-sm", children: "Project Details" }), _jsx(Controller, { control: control, name: "projectName", render: ({ field: { onBlur, onChange, ref, value } }) => (_jsx(CatInputField, { ariaDescribedBy: "text-field-aria-1", "data-test": "project-name-field", fieldId: "text-field-1", label: "Project Name", onBlChange: onChange, onBlur: onBlur, placeholder: "Enter project name", ref: ref, required: true, size: "lg", value: value, ...(errors.projectName && {
                                                        errorNote: errors.projectName.message,
                                                        status: "error"
                                                    }) })) }), _jsx(APIProvider, { apiKey: API_KEY, children: _jsx("div", { children: _jsx(PlaceAutocomplete, { control: control, "data-test": "project-address-field", errors: errors, onPlaceSelect: setSelectedPlace }) }) }), _jsx(Controller, { control: control, name: "owner", render: ({ field: { onBlur, onChange, ref, value } }) => (_jsx(CatInputField, { ariaDescribedBy: "text-field-aria-1", "data-test": "project-owner-field", fieldId: "text-field-1", label: "Owner", onBlChange: onChange, onBlur: onBlur, placeholder: "Enter owner name", ref: ref, required: true, size: "lg", value: value, ...(errors.owner && {
                                                        errorNote: errors.owner.message,
                                                        status: "error"
                                                    }) })) })] }) }) }), _jsx("div", { className: "w-full h-[24rem]", children: _jsx(GoogleMapLocator, { selectedPlace: selectedPlace }) })] })] }), _jsxs("div", { className: "flex gap-1 justify-end px-7 my-2", children: [_jsx(CatButton, { "data-test": "cancel-button", onClick: () => {
                            const isCancelModalShow = (isValid && isDirty) || isUpdateProjectDetails;
                            if (isCancelModalShow) {
                                setIsModalActive(true);
                            }
                            else {
                                setReset();
                                navigate(CaasRoute.Home);
                            }
                        }, size: "sm", children: "Cancel" }), _jsx(CatButton, { "data-test": "step1-next-button", disabled: !isUpdateProjectDetails && (!isValid || !isDirty), onClick: () => {
                            if (isUpdateProjectDetails && (!isValid || !isDirty)) {
                                setActiveStepHandler(activeStep + 1);
                            }
                        }, size: "sm", type: "submit", variant: "primary", children: `${isUpdateProjectDetails && isValid && isDirty ? "Update" : "Next"}` })] }), _jsx("div", { children: _jsx(NewProjectModal, { onModalClose: onModalClose, showModal: isModalActive }) })] }));
};
export default NewProjectForm;
