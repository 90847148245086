import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { zodResolver } from "@hookform/resolvers/zod";
import { CatButton } from "blocks-nextjs/blocks-react/components/Button/Button";
import { CatHeading } from "blocks-nextjs/blocks-react/components/Heading/Heading";
import { CatIconAddCircle } from "blocks-nextjs/blocks-react/components/Icons/AddCircle/AddCircle";
import { CatIconEditBox } from "blocks-nextjs/blocks-react/components/Icons/EditBox/EditBox";
import { CatIconMinusCircle } from "blocks-nextjs/blocks-react/components/Icons/MinusCircle/MinusCircle";
import { CatInputField } from "blocks-nextjs/blocks-react/components/InputField/InputField";
import React, { useEffect, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { CaasRoute } from "../../routes";
import { useUpdateProjectDetailsMutate } from "../../services/query/update-project-details.query";
import { useUpdateProjectAssetDetails } from "../../services/query/updates-project-asset-details.query";
import useCreateNewProjectStore from "../../store/useCreateNewProjectStore";
import useGlobalStore from "../../store/useGlobalStore";
import { Variant } from "../GlobalNotification/GlobalNotification";
import AssetDetailsModal from "./AssetDetailsModal";
import NewProjectModal from "./NewProjectModal";
const CreateProjectAssetPortfolio = ({ activeStep, setActiveStepHandler }) => {
    const [isModalActive, setIsModalActive] = useState(false);
    const [isCancelModalActive, setIsCancelModalActive] = useState(false);
    const [currentAssetIndex, setCurrentAssetIndex] = useState();
    const [isFieldValueEdit, setIsFieldValueEdit] = useState(false);
    const [editAssetInfo, setEditAssetInfo] = useState({
        assetMetadata: {},
        assetName: "",
        assetSerialNumber: "",
        assetSourceId: "",
        assetSourceType: ""
    });
    const { setGlobalNotification, userPartyNumber } = useGlobalStore((state) => ({
        setGlobalNotification: state.setGlobalNotification,
        userPartyNumber: state.userPartyNumber
    }));
    const { assetDetails, projectId, setAssetDetails, setReset } = useCreateNewProjectStore((state) => ({
        assetDetails: state.assetDetails,
        projectId: state.projectId,
        setAssetDetails: state.setAssetDetails,
        setReset: state.setReset
    }));
    const navigate = useNavigate();
    const AssetSchema = z.object({
        assetMetadata: z.object({
            productFamily: z.object({ code: z.string().trim().min(1, "product family code is required") })
        }),
        assetName: z.string().trim().min(2, "Asset name is required"),
        assetSerialNumber: z.string().trim().min(2, "Serial number is required"),
        assetSourceId: z.string().trim().min(1, "Source id is required"),
        assetSourceType: z.string().trim().min(1, "Select any one")
    });
    const AssetListSchema = z.object({
        assetDetails: z.array(AssetSchema).min(1)
    });
    const { control, formState: { errors, isDirty, isValid }, getValues, handleSubmit, setValue, trigger } = useForm({
        defaultValues: {
            assetDetails: []
        },
        mode: "onBlur",
        resolver: zodResolver(AssetListSchema)
    });
    const { append, fields, remove, update } = useFieldArray({
        control,
        name: "assetDetails"
    });
    useEffect(() => {
        setValue("assetDetails", assetDetails, {
            shouldDirty: true,
            shouldValidate: true
        });
    }, [assetDetails]);
    const { mutate } = useUpdateProjectAssetDetails(userPartyNumber, projectId, "create");
    const { mutate: projectActivateMutate } = useUpdateProjectDetailsMutate(userPartyNumber, projectId, "ACTIVE");
    const projectActivateMutateParam = {
        onError: () => {
            setGlobalNotification({
                message: "Error. Failed to activate project. Please try later.",
                showNotification: true,
                type: Variant.error
            });
            setReset();
            navigate(CaasRoute.Home);
        },
        onSuccess: () => {
            setGlobalNotification({
                message: "Success. Project have been activated successfully",
                showNotification: true,
                type: Variant.success
            });
            setReset();
            navigate(CaasRoute.Home);
        }
    };
    const onSubmit = (formData) => {
        mutate(formData, {
            onError: () => {
                setGlobalNotification({
                    message: "Error. Failed to add assets. Please try later.",
                    showNotification: true,
                    type: Variant.error
                });
                projectActivateMutate({}, projectActivateMutateParam);
            },
            onSuccess: () => {
                setGlobalNotification({
                    message: "Success. Assets have been added successfully",
                    showNotification: true,
                    type: Variant.success
                });
                projectActivateMutate({}, projectActivateMutateParam);
            }
        });
    };
    const onCloseModal = () => {
        setIsFieldValueEdit(false);
        setIsModalActive(false);
    };
    const appendFieldValue = (assetInfo) => {
        setIsFieldValueEdit(false);
        append(assetInfo);
        setIsModalActive(false);
    };
    const updateFieldValue = (assetInfo) => {
        setIsFieldValueEdit(false);
        setIsModalActive(false);
        update(currentAssetIndex, assetInfo);
    };
    const onCancelModalClose = (modalState) => {
        setIsCancelModalActive(modalState);
    };
    // generates a unique identifier with given lenght.
    const guid = (length) => {
        // store characters of the generated identifier.
        const identifierCharacters = [];
        // Define a string containing all possible characters for the identifier.
        const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        const charLength = chars.length;
        for (let i = 0; i < length; i++) {
            // Generate a random index to pick a character from the character string.
            identifierCharacters[i] = chars.charAt(Math.floor(Math.random() * charLength));
        }
        // joining the array of characters into a string.
        return identifierCharacters.join("");
    };
    return (_jsxs("div", { children: [_jsxs("form", { onSubmit: handleSubmit(onSubmit), children: [_jsxs("div", { className: "px-7 mt-2.5", children: [_jsxs("div", { className: "flex flex-col gap-1", children: [_jsx(CatHeading, { variant: "title-sm", children: "Asset Portfolio" }), _jsx(CatHeading, { variant: "label-sm", children: "Add the assets (machine & ESS) you employ and the charging schedule you use to manage the electric fleet." })] }), _jsxs("div", { className: "flex h-96 gap-2 my-3.5", children: [_jsx("div", { className: "w-1/3 max-h-96 overflow-y-auto", children: _jsxs("div", { className: "flex flex-col gap-3 bg-[#F2F2F2] p-3 rounded-lg", children: [_jsx(CatHeading, { variant: "display-sm", children: "Asset List" }), _jsx("div", { className: "flex flex-col gap-3", children: fields.map((field, index) => {
                                                        return (
                                                        // eslint-disable-next-line react/jsx-key
                                                        _jsxs("div", { className: "flex gap-3", children: [_jsx("div", { className: "grow", children: _jsx(Controller, { control: control, name: `assetDetails.${index}`, render: ({ field: { onBlur, onChange, ref, value } }) => {
                                                                            return (_jsx(CatInputField, { hideLabel: true, onBlChange: onChange, onBlur: onBlur, placeholder: "Add serial #", readonly: true, ref: ref, size: "md", value: value.assetSerialNumber
                                                                                    ? `${value.assetName} - ${value.assetSerialNumber} - ${value.assetSourceType}`
                                                                                    : "", ...("assetDetails" in errors &&
                                                                                    errors?.assetDetails?.[index] && {
                                                                                    errorNote: errors?.assetDetails?.[index].message,
                                                                                    status: "error"
                                                                                }) }));
                                                                        } }, field.id) }), _jsx(CatButton, { hideText: true, onClick: () => {
                                                                        setCurrentAssetIndex(index);
                                                                        setEditAssetInfo({
                                                                            ...getValues(`assetDetails.${index}`)
                                                                        });
                                                                        setIsFieldValueEdit(true);
                                                                        setIsModalActive(true);
                                                                    }, variant: "ghost", children: _jsxs(React.Fragment, { children: ["Button", _jsx(CatIconEditBox, { slot: "after" })] }, ".0") }), _jsx(CatButton, { hideText: true, onClick: () => {
                                                                        remove(index);
                                                                    }, variant: "ghost", children: _jsxs(React.Fragment, { children: ["Button", _jsx(CatIconMinusCircle, { slot: "after" })] }, ".0") })] }));
                                                    }) }), _jsx("div", { className: "flex justify-end mr-3", children: _jsxs(CatButton, { "data-test": "add-asset-button", onClick: () => {
                                                            setCurrentAssetIndex(fields.length);
                                                            setIsModalActive(true);
                                                        }, size: "sm", children: ["Add Asset", _jsx(CatIconAddCircle, { slot: "after" })] }) })] }) }), _jsx("div", { children: _jsx(AssetDetailsModal, { defaultAssetDetails: isFieldValueEdit
                                                ? { ...editAssetInfo }
                                                : {
                                                    assetMetadata: {
                                                        productFamily: {
                                                            code: "CAT"
                                                        }
                                                    },
                                                    assetName: "",
                                                    assetSerialNumber: "",
                                                    assetSourceId: guid(30),
                                                    assetSourceType: ""
                                                }, heading: "Add Asset", ...(isFieldValueEdit && { onApplyChanges: updateFieldValue }), ...(!isFieldValueEdit && { onApplyChanges: appendFieldValue }), onCloseModal: onCloseModal, showModal: isModalActive, subTitle: "Input the non-CAT OEM details to add to the worksite calculation." }) }), _jsx("div", { className: "w-2/3 gap-3 bg-[#F2F2F2] p-4 rounded-lg", children: _jsx(CatHeading, { variant: "title-sm", children: "Network Diagram" }) })] })] }), _jsxs("div", { className: "flex gap-1 justify-end px-7 my-2", children: [_jsx(CatButton, { onClick: () => {
                                    setIsCancelModalActive(true);
                                }, size: "sm", children: "Cancel" }), _jsx(CatButton, { onClick: () => {
                                    setAssetDetails([...getValues("assetDetails")]);
                                    setActiveStepHandler(activeStep - 1);
                                }, size: "sm", variant: "primary", children: "Back" }), _jsx(CatButton, { disabled: !isDirty || !isValid, onClick: () => {
                                    trigger();
                                }, size: "sm", type: "submit", variant: "primary", children: "Finish" })] })] }), _jsx("div", { children: _jsx(NewProjectModal, { onModalClose: onCancelModalClose, showModal: isCancelModalActive }) })] }));
};
export default CreateProjectAssetPortfolio;
